<template>
  <Main>
    <div class="home"></div>
  </Main>
</template>

<script>
import Main from "../templates/Main.vue";
export default {
  data() {
    return {};
  },
  components: {
    Main,
  },
};
</script>

<style lang="scss" scoped></style>
